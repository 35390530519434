import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Companies as CompaniesView,
  Locations as LocationsView,
  NotFound as NotFoundView,
  Settings as SettingsView,
  SignIn as SignInView,
} from './views';

const Routes = ({ isLoggedIn }) => (
  <Switch>
    <Redirect exact from="/" to={isLoggedIn ? '/companies' : '/sign-in'} />

    <RouteWithLayout
      component={CompaniesView}
      exact
      layout={MainLayout}
      path="/companies"
    />

    <RouteWithLayout
      component={LocationsView}
      exact
      layout={MainLayout}
      path="/locations"
    />

    <RouteWithLayout
      component={SettingsView}
      exact
      layout={MainLayout}
      path="/settings"
    />

    <RouteWithLayout
      component={SignInView}
      exact
      layout={MinimalLayout}
      path="/sign-in"
    />

    <RouteWithLayout
      component={NotFoundView}
      exact
      layout={MinimalLayout}
      path="/not-found"
    />

    <Redirect to="/not-found" />
  </Switch>
);

Routes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default Routes;
