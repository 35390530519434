export const DEFAULT_FORM_DATA = {
  acroCost: 0,
  CompanyId: '',
  goldCost: 0,
  id: '',
  latitude: '',
  longitude: '',
  maxDailyBookings: 5,
  name: '',
  ownerEmail: '',
  ownerName: '',
  phone: '',
  platinumCost: 0,
  region: '',
  showLocation: true,
  silverCost: 0,
};

export const DEFAULT_PLANS = {
  acro: false,
  gold: false,
  platinum: false,
  silver: false,
};

export const DEFAULT_PROMOS = {
  acro: false,
  gold: false,
  platinum: false,
  silver: false,
};

export const DEFAULT_SEASONS = {
  autumn: true,
  spring: true,
  summer: true,
  winter: true,
};

export const DEFAULT_OPEN_DAYS = {
  fri: false,
  mon: false,
  sat: false,
  sun: false,
  thu: false,
  tue: false,
  wed: false,
};
