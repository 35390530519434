import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function AlertDialog(props) {
  const {
    cancel,
    cancelText,
    confirm,
    confirmText,
    isOpen,
    title,
    text,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={cancel} color="secondary" autoFocus>
          {cancelText}
        </Button>

        <Button onClick={confirm} color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
};

AlertDialog.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  text: 'Are you sure?',
  title: 'Confirm',
};
