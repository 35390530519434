import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    alignItems: 'center',
    display: 'flex',
    height: '42px',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const LocationToolbar = props => {
  const { className, disabled, onAddLocation, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button color="primary" disabled={disabled} onClick={onAddLocation} variant="contained">
          Add location
        </Button>
      </div>
    </div>
  );
};

LocationToolbar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onAddLocation: PropTypes.func.isRequired,
};

LocationToolbar.defaultProps = {
  className: '',
};

export default LocationToolbar;
