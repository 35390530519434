import InputAdornment from '@material-ui/core/InputAdornment';
import UITextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const TextField = props => {
  const {
    error,
    name,
    label,
    onChange,
    icon,
    value,
    required,
    ...rest
  } = props;

  let InputProps = icon;

  if (icon) {
    InputProps = {
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
    };
  }

  return (
    <UITextField
      error={!!error}
      fullWidth
      helperText={error}
      label={label}
      margin="dense"
      name={name}
      onChange={onChange}
      required={required}
      type="text"
      value={value}
      variant="outlined"
      InputProps={InputProps}
      {...rest}
    />
  );
};

TextField.propTypes = {
  error: PropTypes.shape(),
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

TextField.defaultProps = {
  error: null,
  icon: null,
  required: true,
  value: '',
};

export default memo(TextField);
