import { FormHelperText } from '@material-ui/core';
import UICheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const Checkbox = props => {
  const { error, name, label, onChange, value } = props;

  return (
    <FormControl component="fieldset" error={!!error}>
      <FormGroup row>
        <FormControlLabel
          control={<UICheckbox checked={value} onChange={onChange} />}
          key={name}
          label={label}
          name={name}
        />
      </FormGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

Checkbox.propTypes = {
  error: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  error: null,
};

export default memo(Checkbox);
