import { AppBar, Badge, Hidden, IconButton, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthAPI } from '../../../../api';
import GlobalState from '../../../../state';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  logo: {
    background: 'white',
    border: '1px solid',
    borderRadius: '0rem 1rem 1rem 0rem',
    marginLeft: '-2rem',
    padding: '5px 25px 5px 2rem',
  },
  root: {
    boxShadow: 'none',
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, history, onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  const { setUser } = useContext(GlobalState);

  const [notifications] = useState([]);

  const logout = useCallback(async () => {
    try {
      await AuthAPI.logout();

      setUser(null);

      history.push('/');
    } catch (error) {
      history.push('/');
    }
  }, [history, setUser]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" className={classes.logo} src="/images/logos/logo--min.svg" />
        </RouterLink>

        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <IconButton className={classes.signOutButton} color="inherit" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.shape().isRequired,
  onSidebarOpen: PropTypes.func.isRequired,
};

Topbar.defaultProps = {
  className: '',
};

export default Topbar;
