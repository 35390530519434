import { Drawer } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import Company from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      href: '/companies',
      icon: <Company />,
      title: 'Companies',
    },

    {
      href: '/locations',
      icon: <LocationOn />,
      title: 'Locations',
    },

    {
      href: '/settings',
      icon: <SettingsIcon />,
      title: 'Settings',
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
