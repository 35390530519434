import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import TableIcons from './icons';

function Table(props) {
  const { columns, data, onRowClick, title, tableRef, ...moreProps } = props;

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        {...moreProps}
        icons={TableIcons}
        columns={columns}
        data={data}
        title={title}
        onRowClick={onRowClick}
        options={{
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [],
          rowStyle: {
            fontFamily: 'Roboto',
            fontSize: '.9rem',
          },
        }}
        tableRef={tableRef}
      />
    </div>
  );
}

Table.defaultProps = {
  columns: [],
  data: [],
  onRowClick: null,
  tableRef: null,
  title: null,
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onRowClick: PropTypes.func,
  tableRef: PropTypes.any,
  title: PropTypes.string,
};

export default Table;
