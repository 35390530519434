export const LOCATION_FIELDS = [
  {
    label: 'Location Name',
    name: 'name',
    required: true,
    size: 12,
    type: 'text',
  },
  {
    label: 'Contact Person Name',
    name: 'ownerName',
    required: true,
    size: 12,
    type: 'text',
  },
  {
    label: 'Contact Person Email',
    name: 'ownerEmail',
    required: false,
    size: 12,
    type: 'email',
  },
  {
    label: 'Contact Person Number',
    name: 'phone',
    required: false,
    size: 12,
    type: 'text',
  },
  {
    label: 'Regione',
    name: 'region',
    required: true,
    size: 12,
    type: 'text',
  },
  {
    label: 'Daily Bookings',
    name: 'maxDailyBookings',
    required: true,
    size: 6,
    type: 'number',
  },
  {
    label: 'Latitude',
    name: 'latitude',
    required: true,
    size: 6,
    type: 'number',
  },
  {
    label: 'Longitude',
    name: 'longitude',
    required: true,
    size: 6,
    type: 'number',
  },
];

export const PROMOS = [
  {
    label: 'Silver',
    name: 'silver',
  },
  {
    label: 'Gold',
    name: 'gold',
  },
  {
    label: 'Platinum',
    name: 'platinum',
  },
  {
    label: 'Acro',
    name: 'acro',
  },
];

export const PLANS = [
  {
    label: 'Silver Plan',
    name: 'silver',
  },
  {
    label: 'Gold Plan',
    name: 'gold',
  },
  {
    label: 'Platinum Plan',
    name: 'platinum',
  },
  {
    label: 'Acro Plan',
    name: 'acro',
  },
];

export const PLANS_COST = [
  {
    id: 'silver',
    label: 'Silver Cost',
    name: 'silverCost',
  },
  {
    id: 'gold',
    label: 'Gold Cost',
    name: 'goldCost',
  },
  {
    id: 'platinum',
    label: 'Platinum Cost',
    name: 'platinumCost',
  },
  {
    id: 'acro',
    label: 'Acro Plan',
    name: 'acroCost',
  },
];

export const SEASONS = [
  {
    label: 'Autunno',
    name: 'autumn',
  },
  {
    label: 'Primavera',
    name: 'spring',
  },
  {
    label: 'Estate',
    name: 'summer',
  },
  {
    label: 'Inverno',
    name: 'winter',
  },
];
