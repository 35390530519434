import axios from 'axios';

export default {
  login: data => axios({
    data,
    method: 'post',
    url: '/auth/login',
  }),

  logout: () => axios({
    method: 'delete',
    url: '/auth/logout',
  }),

  updatePassword: data => axios({
    data,
    method: 'patch',
    url: '/auth/update-password',
  }),
};
