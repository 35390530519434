import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    alignItems: 'center',
    display: 'flex',
    height: '42px',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const CompanyToolbar = props => {
  const { className, disabled, onAddCompany, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          color="primary"
          disabled={disabled}
          onClick={onAddCompany}
          variant="contained"
        >
          Add Company
        </Button>
      </div>
    </div>
  );
};

CompanyToolbar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onAddCompany: PropTypes.func.isRequired,
};

CompanyToolbar.defaultProps = {
  className: '',
};

export default CompanyToolbar;
