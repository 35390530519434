import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CompaniesAPI, LocationsAPI } from '../../api';
import Table from '../../components/Table';
import { LocationForm, LocationToolbar } from './components';
import AlertDialog from '../../components/AlertDialog';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(3),
  },
}));

const columns = [
  { field: 'name', title: 'Location Name' },
  { field: 'ownerName', title: 'Contact Person Name' },
  { field: 'phone', title: 'Contact Person Phone' },
];

const LocationList = props => {
  const { enqueueSnackbar } = props;

  const tableRef = useRef();

  const classes = useStyles();

  const [formErrors, setFormErrors] = useState({});
  const [isDrawerOpen, setDrawer] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedLocation, selectLocation] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);

  const onAddLocation = () => {
    setDrawer(true);
  };

  const onDeleteLocation = async locationId => {
    setLocationToDelete(locationId);
  };

  const deleteLocation = async () => {
    try {
      setLoading(true);

      const res = await LocationsAPI.delete(locationToDelete);

      reloadData();

      setFormErrors({});
      setDrawer(false);

      enqueueSnackbar(res.data.message, { variant: 'success' });
    } catch (error) {
      const errorMsg = error.message;

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
      setLocationToDelete(null);
    }
  };

  const onSelectLocation = (event, { tableData, ...location }) => {
    setDrawer(true);
    selectLocation(location);
  };

  const loadAllCompanies = async () => {
    try {
      setLoading(true);

      const result = await CompaniesAPI.getAll();
      const { data } = result;

      setCompanies(data.data);
    } catch (error) {
      const errorMsg = error.message;
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const loadLocations = async query => {
    try {
      setLoading(true);

      const result = await LocationsAPI.get(query);
      const { data } = result;

      return {
        data: data.data,
        page: data.page,
        totalCount: data.count,
      };
    } catch (error) {
      const errorMsg = error.message;
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const onSubmitForm = async ({ id, ...data }) => {
    try {
      setLoading(true);

      let res;

      if (id) {
        res = await LocationsAPI.update(id, data);
      } else {
        res = await LocationsAPI.add(data);
      }

      reloadData();

      setFormErrors({});
      setDrawer(false);

      enqueueSnackbar(res.data.message, { variant: 'success' });
    } catch (error) {
      let errorMsg = error.message;

      if (error.response.data.error && error.response.data.error.isValidation) {
        setFormErrors(error.response.data.error.fields);
        errorMsg = error.response.data.error.message;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAllCompanies();
  }, []);

  return (
    <div className={classes.root}>
      <LocationToolbar disabled={isLoading} onAddLocation={onAddLocation} />

      <div className={classes.content}>
        <Table
          tableRef={tableRef}
          columns={columns}
          data={loadLocations}
          onRowClick={onSelectLocation}
        />
      </div>

      <LocationForm
        buttonText={selectedLocation ? 'Update Location' : 'Add Location'}
        companies={companies}
        formErrors={formErrors}
        isOpen={isDrawerOpen}
        location={selectedLocation}
        onSubmit={onSubmitForm}
        onDeleteLocation={onDeleteLocation}
        onToggle={setDrawer}
        selectLocation={selectLocation}
      />

      <AlertDialog
        isOpen={!!locationToDelete}
        cancel={() => setLocationToDelete(null)}
        confirm={deleteLocation}
      />
    </div>
  );
};

LocationList.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(LocationList);
