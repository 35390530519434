import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { AuthAPI } from '../../api';
import GlobalState from '../../state';

const schema = {
  email: {
    email: true,
    length: {
      maximum: 64,
    },
    presence: { allowEmpty: false, message: 'is required' },
  },
  password: {
    length: {
      maximum: 128,
    },
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentBody: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    flexGrow: 1,
  },
  contentContainer: {},
  form: {
    flexBasis: 700,
    paddingBottom: 125,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  grid: {
    height: '100%',
  },
  logoImage: {
    transform: 'scale(3)',
  },
  logoImageContainer: {
    display: 'flex',
    'justify-content': 'center',
    'margin-bottom': theme.spacing(5),
  },
  quote: {
    alignItems: 'center',
    backgroundColor: theme.palette.neutral,
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quoteInner: {
    flexBasis: '600px',
    textAlign: 'center',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(3),
  },
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const { setUser } = useContext(GlobalState);

  const [formState, setFormState] = useState({
    errors: {},
    isValid: false,
    touched: {},
    values: {},
  });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState({
      ...formState,
      errors: errors || {},
      isValid: !errors,
    });
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState({
      ...formState,
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleSignIn = async event => {
    event.preventDefault();

    setLoading(true);

    try {
      const { email, password } = formState.values;
      const res = await AuthAPI.login({ password, username: email });

      setUser(res.data.user);
      setFormState({ errors: {}, isValid: false, touched: {}, values: {} });

      history.push('/');
    } catch (error) {
      setFormState({
        ...formState,
        errors: { ...formState.errors, ...error.response.data.error.fields },
        isValid: false,
      });

      setLoading(false);
    }
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Operazione Valchiria pt.2
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <div className={classes.logoImageContainer}>
                  <RouterLink to="/">
                    <img
                      alt="Logo"
                      className={classes.logoImage}
                      src="/images/logos/logo--min.svg"
                    />
                  </RouterLink>
                </div>

                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>

                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email : null}
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />

                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password : null}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />

                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in now
                </Button>

                <Typography align="center" color="error" display="block">
                  {formState.errors.form}
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SignIn);
