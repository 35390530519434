import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { Password } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Settings = props => {
  const { enqueueSnackbar } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={5} xs={12}>
          <Password notify={enqueueSnackbar} />
        </Grid>
      </Grid>
    </div>
  );
};

Settings.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(Settings);
