import axios from 'axios';
import { API_URL } from '../constants';
import { history } from '../helpers';
import AuthAPI from './auth';
import LocationsAPI from './locations';
import CompaniesAPI from './companies';

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      history.push('/sign-in');
    }

    return Promise.reject(error);
  },
);

export { AuthAPI, CompaniesAPI, LocationsAPI };
