import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { CompaniesAPI } from '../../api';
import Table from '../../components/Table';
import { CompanyForm, CompanyToolbar } from './components';
import AlertDialog from '../../components/AlertDialog';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(3),
  },
}));

const columns = [
  { field: 'name', title: 'Name' },
  { field: 'ownerName', title: 'Owner Name' },
  { field: 'ownerEmail', title: 'Owner Email' },
  { field: 'ownerPhone', title: 'Owner Phone' },
  { field: 'address', title: 'Address' },
];

const CompanyList = props => {
  const { enqueueSnackbar } = props;

  const tableRef = useRef();

  const classes = useStyles();

  const [formErrors, setFormErrors] = useState({});
  const [isDrawerOpen, setDrawer] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedCompany, selectCompany] = useState('');
  const [companyToDelete, setCompanyToDelete] = useState(null);

  const onAddCompany = () => {
    setDrawer(true);
  };

  const onSelectCompany = (event, { tableData, ...company }) => {
    setDrawer(true);
    selectCompany(company);
  };

  const loadCompanies = async query => {
    try {
      setLoading(true);

      const result = await CompaniesAPI.get(query);
      const { data } = result;

      return {
        data: data.data,
        page: data.page,
        totalCount: data.count,
      };
    } catch (error) {
      const errorMsg = error.message;
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const onDeleteCompany = async companyId => {
    setCompanyToDelete(companyId);
  };

  const deleteCompany = async () => {
    try {
      setLoading(true);

      const res = await CompaniesAPI.delete(companyToDelete);

      reloadData();

      setFormErrors({});
      setDrawer(false);

      enqueueSnackbar(res.data.message, { variant: 'success' });
    } catch (error) {
      const errorMsg = error.message;

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
      setCompanyToDelete(null);
    }
  };

  const onSubmitForm = async ({ id, ...data }) => {
    try {
      setLoading(true);

      let res;

      if (id) {
        res = await CompaniesAPI.update(id, data);
      } else {
        res = await CompaniesAPI.add(data);
      }

      reloadData();

      setFormErrors({});
      setDrawer(false);

      enqueueSnackbar(res.data.message, { variant: 'success' });
    } catch (error) {
      let errorMsg = error.message;

      if (error.response.data.error && error.response.data.error.isValidation) {
        setFormErrors(error.response.data.error.fields);
        errorMsg = error.response.data.error.message;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <CompanyToolbar disabled={isLoading} onAddCompany={onAddCompany} />

      <div className={classes.content}>
        <Table
          tableRef={tableRef}
          columns={columns}
          data={loadCompanies}
          onRowClick={onSelectCompany}
        />
      </div>

      <CompanyForm
        buttonText={selectedCompany ? 'Update Company' : 'Add Company'}
        formErrors={formErrors}
        isOpen={isDrawerOpen}
        company={selectedCompany}
        onSubmit={onSubmitForm}
        onDeleteCompany={onDeleteCompany}
        onToggle={setDrawer}
        selectCompany={selectCompany}
      />

      <AlertDialog
        isOpen={!!companyToDelete}
        cancel={() => setCompanyToDelete(null)}
        confirm={deleteCompany}
      />
    </div>
  );
};

CompanyList.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(CompanyList);
