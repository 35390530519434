import axios from 'axios';

export default {
  add: data => {
    return axios({
      data,
      method: 'post',
      url: '/companies',
    });
  },

  delete: id => {
    return axios({
      method: 'delete',
      url: `/companies/${id}`,
    });
  },

  get: (query = {}) => {
    let url = '/companies?';

    if (query.orderBy) {
      url += `orderBy=${query.orderBy.field}&`;
      url += `orderDirection=${query.orderDirection}&`;
    }

    if (query.page) {
      url += `page=${query.page}&`;
    }

    if (query.search) {
      url += `search=${query.search}`;
    }

    return axios({
      method: 'get',
      url,
    });
  },

  getAll: () => {
    return axios({
      method: 'get',
      url: '/companies/all',
    });
  },

  update: (id, data) => {
    return axios({
      data,
      method: 'put',
      url: `/companies/${id}`,
    });
  },
};
