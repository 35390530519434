import Checkbox from '@material-ui/core/Checkbox';
import MaterialList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
  },
  inner: {
    minHeight: 500,
    minWidth: 1050,
  },
  progressBar: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
  root: {
    width: '100%',
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

const List = props => {
  const { items, onChange, selected } = props;

  const classes = useStyles();

  return (
    <MaterialList className={classes.root}>
      {items.map(item => {
        const labelId = `checkbox-list-label-${item.name}`;

        return (
          <ListItem key={item.id} role={undefined} dense button onClick={onChange(item.id)}>
            <ListItemIcon>
              <Checkbox
                checked={selected.indexOf(item.id) !== -1}
                disableRipple
                edge="start"
                inputProps={{ 'aria-labelledby': labelId }}
                tabIndex={-1}
              />
            </ListItemIcon>

            <ListItemText id={labelId} primary={item.name} secondary={item.id} />
          </ListItem>
        );
      })}
    </MaterialList>
  );
};

List.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array,
};

List.defaultProps = {
  items: [],
  selected: [],
};

export default List;
