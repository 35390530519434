import { FormHelperText, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UICheckbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Checkbox from '../../../../components/Checkbox';
import TextField from '../../../../components/TextField';
import { DAYS } from '../../../../constants';
import {
  PLANS,
  PLANS_COST,
  PROMOS,
  SEASONS,
  LOCATION_FIELDS,
} from './constants';
import {
  DEFAULT_FORM_DATA,
  DEFAULT_SEASONS,
  DEFAULT_OPEN_DAYS,
  DEFAULT_PLANS,
  DEFAULT_PROMOS,
} from './defaultStates';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    '&:hover': {
      backgroundColor: '#da2e21',
    },
    backgroundColor: '#f44336',
    marginLeft: '1rem',
  },

  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  drawer: {
    padding: theme.spacing(3),
    width: '75%',
  },

  formControl: {
    minWidth: '100%',
  },

  root: {
    padding: theme.spacing(3),
  },
}));

const LocationForm = props => {
  const {
    buttonText,
    className,
    companies,
    formErrors,
    isOpen,
    location,
    onSubmit,
    onToggle,
    onDeleteLocation,
    selectLocation,
    ...rest
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    ...DEFAULT_FORM_DATA,
  });

  const [plans, setPlans] = useState({ ...DEFAULT_PLANS });
  const [promos, setPromo] = useState({ ...DEFAULT_PROMOS });
  const [seasons, setSeasons] = useState({ ...DEFAULT_SEASONS });
  const [openDays, setOpenDays] = useState({ ...DEFAULT_OPEN_DAYS });

  useEffect(() => {
    if (location) {
      const {
        availablePlans,
        availablePromos,
        openDays,
        supportedSeasons,
        ...formData
      } = location;

      setFormData(formData);
      setPlans(availablePlans);
      setPromo(availablePromos);
      setOpenDays(openDays);
      setSeasons(supportedSeasons);
    }
  }, [location]);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!open) {
      setFormData({
        ...DEFAULT_FORM_DATA,
      });
    }

    selectLocation(null);
    onToggle(open);
  };

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData],
  );

  const onChangeOpenDays = useCallback(
    event => {
      setOpenDays({
        ...openDays,
        [event.target.name]: event.target.checked,
      });
    },
    [openDays],
  );

  const onChangePlans = useCallback(
    event => {
      setPlans({
        ...plans,
        [event.target.name]: event.target.checked,
      });
    },
    [plans],
  );

  const onChangePromo = useCallback(
    event => {
      setPromo({
        ...promos,
        [event.target.name]: event.target.checked,
      });
    },
    [promos],
  );

  const onChangeSeasons = useCallback(
    event => {
      setSeasons({
        ...seasons,
        [event.target.name]: event.target.checked,
      });
    },
    [seasons],
  );

  const onShowCheckmarkChange = useCallback(
    event => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.checked,
      });
    },
    [formData],
  );

  const submit = () => {
    onSubmit({
      ...formData,
      availablePlans: { ...plans },
      availablePromos: { ...promos },
      openDays: { ...openDays },
      supportedSeasons: { ...seasons },
    });
  };

  return (
    <Drawer
      {...rest}
      anchor="right"
      classes={{
        paper: classes.drawer,
        root: classes.root,
      }}
      className={className}
      open={isOpen}
      onClose={toggleDrawer(false)}
    >
      <form autoComplete="off" noValidate>
        <Typography variant="h3" gutterBottom>
          Add Location
        </Typography>

        <Divider className={classes.divider} />

        <Grid container spacing={3}>
          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Location Details</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                className={classes.formControl}
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="CompanyId-label">Company</InputLabel>

                <Select
                  error={!!formErrors.CompanyId}
                  labelId="CompanyId-label"
                  id="CompanyId"
                  label="CompanyId"
                  name="CompanyId"
                  onChange={handleChange}
                  required
                  value={formData.CompanyId}
                >
                  {companies.map(company => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formErrors.CompanyId}</FormHelperText>
              </FormControl>
            </Grid>

            {LOCATION_FIELDS.map(field => (
              <Grid item key={field.name} md={field.size} xs={12}>
                <TextField
                  error={formErrors[field.name]}
                  label={field.label}
                  name={field.name}
                  onChange={handleChange}
                  required={field.required}
                  type={field.type}
                  value={formData[field.name]}
                />
              </Grid>
            ))}
          </Grid>

          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Plans</Typography>
            </Grid>

            {PLANS_COST.map((plan, index) => (
              <Fragment key={plan.id}>
                <Grid item md={6} xs={12}>
                  <Checkbox
                    name={PLANS[index].name}
                    error={formErrors.availablePlans}
                    label={PLANS[index].label}
                    onChange={onChangePlans}
                    value={plans[plan.id]}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={!plans[plan.id]}
                    error={formErrors[plan.name]}
                    icon="$"
                    label={plan.label}
                    name={plan.name}
                    onChange={handleChange}
                    type="number"
                    value={formData[plan.name]}
                  />
                </Grid>
              </Fragment>
            ))}

            <Grid item md={12} xs={12}>
              <Typography variant="h5">Pacchetto Promo</Typography>
            </Grid>

            {PROMOS.map(promo => (
              <Grid item key={promo.name} md={6} xs={12}>
                <Checkbox
                  name={promo.name}
                  error={formErrors.availablePromos}
                  label={promo.label}
                  onChange={onChangePromo}
                  value={promos[promo.name]}
                />
              </Grid>
            ))}

            <Grid item md={12} xs={12}>
              <Typography variant="h5">Seasons</Typography>
            </Grid>

            {SEASONS.map(season => (
              <Grid item key={season.name} md={6} xs={12}>
                <Checkbox
                  name={season.name}
                  error={formErrors.supportedSeasons}
                  label={season.label}
                  onChange={onChangeSeasons}
                  value={seasons[season.name]}
                />
              </Grid>
            ))}
          </Grid>

          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Availability</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl component="fieldset" error={!!formErrors.openDays}>
                <FormLabel component="legend">Open Days</FormLabel>
                <FormGroup>
                  {DAYS.map(day => (
                    <FormControlLabel
                      control={
                        <UICheckbox
                          checked={openDays[day.code]}
                          onChange={onChangeOpenDays}
                        />
                      }
                      key={day.code}
                      label={day.label}
                      name={day.code}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>{formErrors.openDays}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography variant="h5">Others</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                component="fieldset"
                error={!!formErrors.showLocation}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <UICheckbox
                        checked={formData.showLocation}
                        onChange={onShowCheckmarkChange}
                      />
                    }
                    key="showLocation"
                    label="Show Location"
                    name="showLocation"
                  />
                </FormGroup>
                <FormHelperText>{formErrors.showLocation}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Button onClick={() => submit()} color="primary" variant="contained">
          {buttonText}
        </Button>

        {location ? (
          <Button
            className={classes.deleteButton}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteLocation(formData.id)}
          >
            Delete
          </Button>
        ) : null}
      </form>
    </Drawer>
  );
};

LocationForm.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  companies: PropTypes.array,
  formErrors: PropTypes.shape(),
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape(),
  onDeleteLocation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
};

LocationForm.defaultProps = {
  buttonText: 'Submit',
  className: '',
  companies: [],
  formErrors: {},
  location: null,
};

export default LocationForm;
