import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AuthAPI } from '../../../../api';

const useStyles = makeStyles(theme => ({
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: {},
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const Password = props => {
  const { notify, ...rest } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    confirmPassword: '',
    currentPassword: '',
    newPassword: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const [isLoading, setLoading] = useState(false);

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async () => {
    try {
      const { confirmPassword, currentPassword, newPassword } = formData;

      if (newPassword.length < 8) {
        setFormErrors({ password: 'Length of password must be 8 or more' });
        return;
      }

      if (newPassword !== confirmPassword) {
        setFormErrors({ confirm: 'Password does not match' });
        return;
      }

      setLoading(true);

      const res = await AuthAPI.updatePassword({ currentPassword, newPassword });

      setFormData({ confirmPassword: '', currentPassword: '', newPassword: '' });
      setFormErrors({});

      notify(res.data.message, { variant: 'success' });
    } catch (error) {
      let errorMsg = error.message;

      if (error.response.data.error && error.response.data.error.isValidation) {
        setFormErrors(error.response.data.error.fields);
        errorMsg = error.response.data.error.message;
      }

      notify(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card {...rest} className={classes.root}>
      <form>
        <CardHeader subheader="Always choose a unique password." title="Password" />

        <Divider />

        <CardContent>
          <TextField
            className={classes.textField}
            error={!!formErrors.currentPassword}
            fullWidth
            helperText={formErrors.currentPassword}
            label="Current Password"
            margin="dense"
            name="currentPassword"
            onChange={handleChange}
            type="password"
            value={formData.currentPassword}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            error={!!formErrors.newPassword}
            fullWidth
            helperText={formErrors.newPassword}
            label="New Password"
            margin="dense"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={formData.newPassword}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            error={!!formErrors.confirmPassword}
            fullWidth
            helperText={formErrors.confirmPassword}
            label="Confirm Password"
            margin="dense"
            name="confirmPassword"
            onChange={handleChange}
            type="password"
            value={formData.confirmPassword}
            variant="outlined"
          />
        </CardContent>

        <Divider />

        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            disabled={
              isLoading
              || !formData.confirmPassword
              || !formData.currentPassword
              || !formData.newPassword
            }
            onClick={onSubmit}
            variant="contained"
          >
            Change Password
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  notify: PropTypes.func.isRequired,
};

export default Password;
