import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Router } from 'react-router-dom';
import validate from 'validate.js';
import './assets/scss/index.scss';
import validators from './common/validators';
import { history } from './helpers';
import Routes from './Routes';
import GlobalState from './state';
import theme from './theme';

validate.validators = {
  ...validate.validators,
  ...validators,
};

class App extends Component {
  state = {
    isLoggedIn: localStorage.getItem('loggedIn') === 'true',
    user: null,
  };

  setUser = user => {
    this.setState({ isLoggedIn: !!user, user });
    localStorage.setItem('loggedIn', user !== null);
  };

  render() {
    const { isLoggedIn, user } = this.state;

    const provider = { setUser: this.setUser, user };

    return (
      <GlobalState.Provider value={provider}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={2000}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            maxSnack={3}
          >
            <Router history={history}>
              <Routes isLoggedIn={isLoggedIn} />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </GlobalState.Provider>
    );
  }
}

export default App;
