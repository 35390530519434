import { Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';

const DEFAULT_FORM_DATA = {
  address: '',
  bank: '',
  iban: '',
  id: '',
  name: '',
  ownerEmail: '',
  ownerName: '',
  ownerPhone: '',
  swift: '',
  vat: '',
};

const useStyles = makeStyles(theme => ({
  deleteButton: {
    '&:hover': {
      backgroundColor: '#da2e21',
    },
    backgroundColor: '#f44336',
    marginLeft: '1rem',
  },

  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  drawer: {
    padding: theme.spacing(3),
    width: '33%',
  },

  root: {
    padding: theme.spacing(3),
  },
}));

const CompanyForm = props => {
  const {
    buttonText,
    className,
    formErrors,
    isOpen,
    company,
    onSubmit,
    onDeleteCompany,
    onToggle,
    selectCompany,
    ...rest
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    ...DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    if (company) {
      setFormData(company);
    }
  }, [company]);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!open) {
      setFormData({
        ...DEFAULT_FORM_DATA,
      });
    }

    selectCompany(null);
    onToggle(open);
  };

  const handleChange = useCallback(
    event => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    },
    [formData],
  );

  return (
    <Drawer
      {...rest}
      anchor="right"
      classes={{
        paper: classes.drawer,
        root: classes.root,
      }}
      className={className}
      open={isOpen}
      onClose={toggleDrawer(false)}
    >
      <form autoComplete="off" noValidate>
        <Typography variant="h3" gutterBottom>
          {buttonText}
        </Typography>

        <Divider className={classes.divider} />

        <Grid container spacing={3}>
          <Grid
            alignContent="flex-start"
            container
            item
            md={12}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Company Details</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.name}
                fullWidth
                helperText={formErrors.name}
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={formData.name}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.address}
                fullWidth
                helperText={formErrors.address}
                label="Address"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={formData.address}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.ownerName}
                fullWidth
                helperText={formErrors.ownerName}
                label="Owner Name"
                margin="dense"
                name="ownerName"
                onChange={handleChange}
                required
                value={formData.ownerName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.ownerEmail}
                fullWidth
                helperText={formErrors.ownerEmail}
                label="Owner Email"
                margin="dense"
                name="ownerEmail"
                onChange={handleChange}
                required
                value={formData.ownerEmail}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.ownerPhone}
                fullWidth
                helperText={formErrors.ownerPhone}
                label="Owner Phone"
                margin="dense"
                name="ownerPhone"
                onChange={handleChange}
                required
                value={formData.ownerPhone}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.vat}
                fullWidth
                helperText={formErrors.vat}
                label="Codice Fiscale"
                margin="dense"
                name="vat"
                onChange={handleChange}
                required
                value={formData.vat}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.bank}
                fullWidth
                helperText={formErrors.bank}
                label="Bank"
                margin="dense"
                name="bank"
                onChange={handleChange}
                value={formData.bank}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.iban}
                fullWidth
                helperText={formErrors.iban}
                label="IBAN"
                margin="dense"
                name="iban"
                onChange={handleChange}
                value={formData.iban}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.swift}
                fullWidth
                helperText={formErrors.swift}
                label="SWIFT"
                margin="dense"
                name="swift"
                onChange={handleChange}
                value={formData.swift}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Button
          onClick={() => onSubmit(formData)}
          color="primary"
          variant="contained"
        >
          {buttonText}
        </Button>

        {company ? (
          <Button
            className={classes.deleteButton}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteCompany(formData.id)}
          >
            Delete
          </Button>
        ) : null}
      </form>
    </Drawer>
  );
};

CompanyForm.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  company: PropTypes.shape(),
  formErrors: PropTypes.shape(),
  isOpen: PropTypes.bool.isRequired,
  onDeleteCompany: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  selectCompany: PropTypes.func.isRequired,
};

CompanyForm.defaultProps = {
  buttonText: 'Submit',
  className: '',
  company: null,
  formErrors: {},
};

export default CompanyForm;
